<template>
  <div id="accountSafety">
    <header-nav :content="`${$t('pages_router_023')} / ${ $t('pages_router_033')}`" />
    <div class="common-card-body">
      <div v-if="getUsedCheck(pageLabs, 170301)" class="safety-card flex-align">
        <div class="area-left flex-align">
          <img
            src="~@/assets/icons/ic-locked.png"
            class="left-img"
            style="height: 32px"
          >
          <span class="title">{{ $t('pages_user_048') }}</span>
          <span class="sub-title">{{ $t('pages_user_051') }}</span>
        </div>
        <div class="area-right flex-align">
          <a-button
            type="link"
            @click="editPassword('login_update')"
          >{{ $t('pages_user_052') }}</a-button>
        </div>
      </div>
      <div v-if="getUsedCheck(pageLabs, 170302)" class="safety-card flex-align">
        <div class="area-left flex-align">
          <img src="~@/assets/icons/ic-money.png" class="left-img">
          <span class="title">{{ $t('pages_user_049') }}</span>
          <span v-if="isExist" class="sub-title">{{ $t('pages_user_051') }}</span>
        </div>
        <div class="area-right flex-align">
          <a-button
            v-if="isExist"
            type="link"
            @click="editPassword('pay_update')"
          >{{ $t('pages_user_053') }}</a-button>
          <a-button
            v-else
            type="link"
            @click="editPassword('pay_set')"
          >{{ $t('pages_user_065') }}</a-button>
          <a-button
            v-if="isExist"
            type="link"
            @click="editPassword('pay_reset')"
          >{{ $t('pages_user_054') }}</a-button>
        </div>
      </div>
      <!-- <div v-if="getUsedCheck(pageLabs, 160302)" class="safety-card flex-align">
        <div class="area-left flex-align">
          <img src="~@/assets/icons/ic-shield.png" class="left-img">
          <span class="title">{{ $t('pages_user_050') }}</span>
          <span v-if="checked" class="sub-title">{{ $t('pages_user_056') }}</span>
          <span v-else class="sub-title">{{ $t('pages_user_055') }}</span>
        </div>
        <div class="area-right flex-align">
          <a-switch v-model:checked="checked" style="margin-right: 15px" />
        </div>
      </div> -->
    </div>
    <a-modal
      wrap-class-name="password-form"
      :visible="visible"
      :width="modalWidth"
      :mask-closable="false"
      :title="editText === 'login_update' ? $t('pages_user_062'):
        editText === 'pay_set' ? $t('pages_user_065') :
        editText === 'pay_update' ? $t('pages_user_063') : $t('pages_user_064')"
      :confirm-loading="loading"
      :ok-text="$t('common_text_002')"
      @ok="handleSubmit"
      @cancel="() => visible = false"
    >
      <a-form ref="editRef" class="form-edit" label-align="left" :model="editForm" :rules="editRules" :label-col="{span: 5}">
        <a-form-item v-if="['login_update', 'pay_update'].includes(editText)" name="oldPassword" :label="$t('pages_user_057')">
          <a-input-password
            v-model:value="editForm.oldPassword"
            :placeholder="$t('common_text_006') + $t('pages_user_057')"
          />
        </a-form-item>
        <a-form-item v-if="editText === 'pay_reset'" :required="true" :label="$t('pages_user_070')">
          <div class="flex-align">
            <div class="email-text">{{ email }}</div>
            <a-button
              type="primary"
              style="margin-left: auto"
              :disabled="countNum > 0"
              :loading="codeLoading"
              @click="sendCode"
            >{{ countNum > 0 ? `${countNum}${$t('pages_user_018')}` : $t('pages_user_071') }}</a-button>
          </div>
        </a-form-item>
        <a-form-item v-if="editText === 'pay_reset'" name="code" :label="$t('pages_user_020')">
          <a-input
            v-model:value="editForm.code"
            allow-clear
            autocomplete="new-password"
            :placeholder="$t('common_text_006') + $t('pages_user_020')"
          />
        </a-form-item>
        <a-form-item name="newPassword" :label="$t('pages_user_058')">
          <a-input-password
            v-model:value="editForm.newPassword"
            autocomplete="new-password"
            :placeholder="$t('common_text_006') + $t('pages_user_058')"
          />
        </a-form-item>
        <a-form-item :wrapper-col="{ offset: 5 }">
          <div style="font-weight: 500">{{ $t('pages_user_066') }}</div>
          <div style="margin-top: 8px">{{ $t('pages_user_067') }}</div>
          <div style="margin-top: 8px">{{ $t('pages_user_068') }}</div>
          <div style="margin-top: 8px">{{ $t('pages_user_069') }}</div>
        </a-form-item>
        <a-form-item name="secPassword" :label="$t('pages_user_059')">
          <a-input-password
            v-model:value="editForm.secPassword"
            autocomplete="new-password"
            :placeholder="$t('common_text_006') + $t('pages_user_059')"
          />
        </a-form-item>
      </a-form>
    </a-modal>
  </div>
</template>
<script>
import HeaderNav from '@/components/header-nav'
import { getUsedCheck } from '@/util'
import { ref, reactive, toRefs, onUnmounted } from 'vue'
import { useStore } from 'vuex'
import { message } from 'ant-design-vue'
import { _account, _pay, _user } from '@/api'
import i18n from '@/locale'
import md5 from 'js-md5'
export default {
  name: 'AccountSafety',
  components: {
    'header-nav': HeaderNav
  },
  setup () {
    const validPasswordFormat = (rule, value) => {
      const pattern = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[a-zA-Z\d]{8,20}$/
      if (!value) return Promise.reject(i18n.global.t(rule.field === 'secPassword' ? 'pages_user_059' : 'pages_user_058') + i18n.global.t('common_text_042'))
      if (!pattern.test(value)) return Promise.reject(i18n.global.t('pages_user_060'))
      if (rule.field === 'secPassword' && value !== state.editForm.newPassword) return Promise.reject(i18n.global.t('pages_user_061'))
      return Promise.resolve()
    }

    let timer = ref(null)
    const editRef = ref(null)
    const store = useStore()

    const state = reactive({
      email: store.getters.getUserInfo.email,
      modalWidth: localStorage.getItem('locale-language') === 'zh' ? 488 : 728,
      countNum: 0,
      codeLoading: false,
      checked: false,
      pageLabs: [],
      editText: 'login_update',
      visible: false,
      loading: false,
      isExist: false,
      editForm: {
        oldPassword: '',
        newPassword: '',
        secPassword: '',
        code: ''
      },
      editRules: {
        oldPassword: [{ required: true, message: i18n.global.t('pages_user_057') + i18n.global.t('common_text_042') }],
        newPassword: [{ required: true, validator: validPasswordFormat }],
        secPassword: [{ required: true, validator: validPasswordFormat }],
        code: [{ required: true, message: i18n.global.t('pages_user_020') + i18n.global.t('common_text_042') }]
      }
    })

    onUnmounted(() => {
      clearInterval(timer)
    })

    const sendCode = async () => {
      state.codeLoading = true
      const res = await _user.sendCodeEmail()
      state.codeLoading = false
      if (res.data.succ) {
        state.countNum = 60
        timer = setInterval(() => {
          state.countNum--
          if (state.countNum === 0) clearInterval(timer)
        }, 1000)
      } else {
        message.error(res.data.resMsg)
      }
    }

    const editPassword = (editText) => {
      state.visible = true
      state.editText = editText
      if (editRef.value) editRef.value.resetFields()
    }

    const handleSubmit = () => {
      editRef.value.validateFields()
        .then(values => {
          const newPayPwd = [{ pwd: md5(state.email + values.newPassword), pwdIndex: 0 }]
          const oldPayPwd = [{ pwd: md5(state.email + values.oldPassword), pwdIndex: 0 }]
          if (state.editText === 'login_update') {
            return _user.modifyPassword(values)
          } else if (state.editText === 'pay_set') {
            return _user.setPayPassword(newPayPwd)
          } else if (state.editText === 'pay_update') {
            return _user.modifyPayPassword({ newPayPwd, oldPayPwd })
          } else {
            return _user.resetPayPassword({ newPayPwd, code: values.code })
          }
        })
        .then(res => {
          if (res.data.succ) {
            state.visible = false
            message.success(i18n.global.t('pages_user_072'))
            loadPayPwdExist()
          } else {
            message.error(res.data.resMsg)
          }
        })
        .catch(err => console.log(err))
    }

    const loadChildrenMenu = async () => {
      const res = await _account.getChildrenMenu({ parentId: 1703 })
      if (res.data.succ) {
        state.pageLabs = res.data.data || []
      }
    }

    const loadPayPwdExist = async () => {
      const res = await _pay.isPayPwdExist()
      if (res.data.succ) state.isExist = res.data.data
    }

    loadChildrenMenu()
    loadPayPwdExist()

    return {
      editRef,
      sendCode,
      getUsedCheck,
      editPassword,
      handleSubmit,
      ...toRefs(state)
    }
  }
}
</script>
<style lang="less" scoped>
#accountSafety {
  .common-card-body {
    padding: 36px 48px;
    .safety-card {
      height: 135px;
      line-height: 135px;
      border-radius: 8px;
      border: 1px solid #E9E9E9;
      padding: 0 24px;
      margin-bottom: 24px;
      .area-left {
        .left-img {
          width: 35px;
          height: 35px;
          margin-top: -3px;
        }
        .title {
          font-size: 16px;
          font-weight: 500;
          margin-left: 20px;
        }
        .sub-title {
          margin-left: 12px;
        }
      }
      .area-right {
        margin-left: auto;
      }
    }
  }
}
.password-form {
  .email-text {
    border: 1px solid #d9d9d9;
    line-height: 30px;
    height: 32px;
    padding: 0 12px;
    width: 60%;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
  .form-edit:deep(.ant-form-item-label) {
    white-space: normal;
    overflow: inherit;
  }
}
</style>

